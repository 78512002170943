<div class="article">
    <div class="article_image mr-1" [style.background-image]="'url(' + article.thumbnail + ')'">
        <div class="no-image" *ngIf="!article.thumbnail">{{'article_no_image' | translate}}</div>
        <ks-modal-gallery [id]="article.id" [modalImages]="[image]"
                          [currentImageConfig]="{description: imageDescription}"
                          [slideConfig]="{infinite: false, sidePreviews: {show: false}}"
                          [buttonsConfig]="buttonsConfig"
                          [previewConfig]="{visible: false}"
                          [dotsConfig]="{visible: false}"></ks-modal-gallery>
    </div>
    <div class="article_details">
        <p class="article_name">{{article.name}}{{article.packingUnit && article.packingUnit != "" ? " (" + article.packingUnit + ")" : "" }}</p>
        <p *ngIf="article.price && article.price > 0">{{ (article.price | currency:'EUR').replace('.',',')}}</p>
        <p>{{article.articleGroup.name}}</p>
        <p [innerHTML]="article.description"></p>
    </div>
    <div class="article-controls" *ngIf="article.stockStatus === 'AVAILABLE'">
        <div class="flex-column v-end h-center">
            <div class="v-center">
                <input appNumber
                       [max]="maxQuantity()"
                       [maxLength]="5"
                       [decimal]="0"
                       class="input mr-1"
                       type="text"
                       [(ngModel)]="quantity">
                <button class="btn-pure" (click)="addToCart()">
                    <img src="assets/img/cart-add.svg" alt="">
                </button>
            </div>
        </div>
    </div>
    <div class="article-controls" *ngIf="article.stockStatus === 'OUT_OF_STOCK'">
        <div class="flex-column v-end">
            <mat-checkbox [checked]="article.stockNotification" (change)="subscribeNotification($event)">
                {{'mail_stock' | translate}}
            </mat-checkbox>
            <small class="mt-2">{{'not_on_stock' | translate}}</small>
        </div>
    </div>
</div>
