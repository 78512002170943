import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { AppRoutingModule } from "./app-routing.module";
import { NgProgressModule } from "ngx-progressbar";
import { NgProgressHttpModule } from "ngx-progressbar/http";
import { NgProgressRouterModule } from "ngx-progressbar/router";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        CoreModule,
        AppRoutingModule,
        NgProgressModule.withConfig({
            color: "#4e2780",
            meteor: true,
            speed: 200,
            trickleSpeed: 100,
        }),
        NgProgressHttpModule,
        NgProgressRouterModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
